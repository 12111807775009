<template>
<section id="iq-upcoming-movie">
  
  
  
  
  
  
    <b-container fluid>
        <b-row>
            <b-col sm="12" class="overflow-hidden">
               
                   
                  <br> 
                  <br> 
                  <br>
                <div class="iq-main-header d-flex align-items-center justify-content-between">
               
                    <h4 class="main-title">{{contents[0]['content_category']?.[$i18n.locale]}}</h4>
                </div>
                <div class="favorites-contens">
                  <!-- <Slick class="favorites-slider list-inline  row p-0 mb-0 iq-rtl-direction" ref="dSlick" :option="sliderOption"> -->
            <div class="favorites-slider list-inline  row p-0 mb-0 iq-rtl-direction">
              <li  class="slide-item mb-4 col-12 col-sm-6 col-md-4 col-lg-3" v-for="(item, index) in contents" :key="index">
                <div class="block-images position-relative">
                  <div class="img-box">
                    <img :src="item.content_thumbnail" class="img-fluid" alt="">
                  </div>
                  <div class="block-description">
                    
                    <h6 class="iq-title"><a @click="gotoPage('/movie-detail/'+item.id)">{{ item.content_title?.[$i18n.locale] }}
                    </a></h6>
                    
                    <div class="hover-buttons" v-if="$i18n.locale == 'en'">
                      <a @click="gotoPage('/movie-detail/'+item.id)" role="button" class="btn btn-hover"><i
                          class="fa fa-play mr-1" aria-hidden="true">  Play Now </i>
                        </a>   
                      </div>
                        
                      <div class="hover-buttons" v-if="$i18n.locale == 'ku'">
                      <a @click="gotoPage('/movie-detail/'+item.id)" role="button" class="btn btn-hover"><i
                          class="fa fa-play mr-1" aria-hidden="true">  ڤیدیۆکە لێبدە </i>
                        </a>   
                      </div>
                        
                      
                      <div class="hover-buttons" v-if="$i18n.locale == 'ar'">
                      <a @click="gotoPage('/movie-detail/'+item.id)" role="button" class="btn btn-hover"><i
                          class="fa fa-play mr-1" aria-hidden="true">  شغل الفيديو </i>
                        </a>   
                      </div>
                      
                      
                      
                      
                    
                  </div>
                </div>
              </li>
            </div>
            <!-- </Slick> -->
                </div>
            </b-col>
        </b-row>
    </b-container>
</section>
</template>
<script>
import AlertServie from '../../../services/errors'

export default {
  name: 'PopularShow',
  props: ['contents'],
  components: {
  },
  mounted () {
  },
  methods: {
    gotoPage (link) {
      var points = this.$store.state.points
      if (points > 0) {
        this.$router.push(link)
      } else {
        AlertServie.errorMessage("You have used all your daily points. You will receive 3 more points tomorrow.")
      }
    },
    next () {
      this.$refs.dSlick.next()
    },
    prev () {
      this.$refs.dSlick.prev()
    }
  }
}

</script>


<style>



.main-title
{
  font-size: 22px;
}


</style>
